import React from "react";
import { LockedPage, ExpertDashboard } from "../components";
import { PageTitle } from "../components/common";

export default function HomePage() {
  return (
    <LockedPage>
      <PageTitle title="Dashboard" />
      <ExpertDashboard />
    </LockedPage>
  );
}
